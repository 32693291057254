import React from "react"

class BlockHeader extends React.Component {

    render() {
        return (
            <div className="bg-blue-100 text-center py-2">
                {this.props.title}
            </div>
        )
    }
}

export default BlockHeader