import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import BlockHeader from "../components/blockheader"

import symbol from "../../content/assets/symbolic_image.svg"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div className="rounded rounded-t-lg overflow-hidden shadow max-w-xs my-3">
            <BlockHeader title={"About"}/>
            <div className="flex justify-center mt-3">
              <div className="w-24 md:w-32"><img src={symbol} alt="WCCA" /></div>
            </div>
            <div className="text-center px-3 pb-6 pt-2">
              <div className="mt-2 font-light text-grey-dark">
                <p>
                  The WCCA works to further the recognition, professionalism, and excellence of contact creators.
                </p>
                <p>
                  We are open to new members who seek to demonstrate the professional quality of their work, engage
                  in the industry, and find out about new opportunities to learn, grow, and work.
                </p>
                <p>
                  Check out our <Link className="border-b border-blue-200" to="/membership">Membership</Link> page for
                  more details.
                </p>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

/*
<Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                className="border-solid border-white border-2 -mt-1"
              />
*/

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/me_hkg__43_750.jpg/" }) {
      childImageSharp {
        fixed(width: 132, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
