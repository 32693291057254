import React from "react"
import { graphql, Link } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

class AdvertisingPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy" />
        <div className="w-full">
          <div className="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
            <h1 className="text-xl md:text-4xl pb-4">Privacy Policy</h1>
          </div>
          <div className="simple-post container max-w-5xl mx-auto py-8">
            <h2>Who we are</h2>
            <p>Our website address is: https://webcontentcreatorsassociation.org.
              We can be reached at <Mailto email="privacy@webcontentcreatorsassociation.org"></Mailto>.
            </p>

            <h2>What personal data we collect and why we collect it</h2>
            <h3>Cookies</h3>
            <p>
              From time to time we use third party advertisements on
              webcontentcreators.org. Some of these advertisers may use
              technology such as cookies and web beacons when they advertise
              on our site, which will also send these advertisers (such as
              Google through the Google AdSense program) information including
              your IP address, your ISP, the browser you used to visit our site,
              and in some cases whether you have Flash installed. This is
              generally used for geotargeting purposes or showing certain ads
              based on specific sites visited.
            </p>
            <p>
              You can choose to disable or selectively turn off our cookies or
              third-party cookies in your browser settings, or by managing
              preferences in a third party program of your choice. However,
              this can affect how you are able to interact with our site as
              well as other websites. This could include the inability to login
              to services or programs, such as logging into forums or accounts.
            </p>

            <h3>Embedded content from other websites</h3>
            <p>
              Pages on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
            </p>
            <p>
              These websites may collect data about you, use cookies, embed additional
              third-party tracking, and monitor your interaction with that embedded
              content, including tracing your interaction with the embedded content if
              you have an account and are logged in to that website.
            </p>

            <h3>Analytics</h3>
            <p>
              As you use landinglastminute.com, we use automatic data collection
              technology (Google Analytics) to collect certain infromation about your
              device, browsing actions, and patterns. This generally includes information
              about where you are, how you use our website, and any communications
              between your computer and this site. Among other things, we will collect
              data about the type of computer you use, your Internet connection, your IP
              address, your operating systems, and your browser type.
            </p>
            <p>
              We collect this data for statistical purposes and we don't collect
              personal information. The purpose of this data is to improve
              landinglastminute.com and offerings.
            </p>
            <p>
              If you wish to opt out of Google Analytics so that none of your personal
              information is collected and stored by Google Analytics, you can{` `}
              <a href="https://tools.google.com/dlpage/gaoptout">download and install
              the Google Analytics Opt-out Browser Add-on here</a>. For more information
              on how Google collects and uses your data, you can <a href="https://policies.google.com/privacy?hl=en">
                access the Google Privacy Policy here.
              </a>
            </p>

            <h2>Who we share your data with</h2>
            <p>
              We don't sell or share your data with anyone.
            </p>

            <h2>Online Behavioural Advertising</h2>
            <p>
              This website may participate in Online Behavioural Advertising (OBA) programmes.
              Third parties engaging in OBA activities on this site are listed in this section of the
              privacy policy as they participate.
            </p>
            <p>
              Further details can be found at the <a href="www.digitaladvertisingalliance.org">DAA's website</a>.
            </p>
            <p>
              WebChoices is available here:{` `}<a href="https://www.aboutads.info/choices">https://www.aboutads.info/choices</a>.
            </p>

            <h2>Our contact information</h2>
            <p>
              For questions regarding your data and how we use it, you can reach
              us at <Mailto email="privacy@webcontentcreatorsassociation.org"></Mailto>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

/*
<p>
              <em>Landing last minute</em> maintains a privacy policy regarding any information we may collect from you across our website.
              </p><p>
              <em>Landing last minute</em> only asks for personal information as is needed to provide the services on our website. This site
              is monitored by Google Analytics. Google may acquire information about users of this site which can be used
              by us as information on site usage. If you wish to opt out of Google Analytics, more information can be found <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
              </p><p>
              <em>Landing last minute</em> retains collected information only for as long as necessary to provide you with the services on this website. All reasonable steps have been taken to prevent loss and theft, unauthorised access, disclosure, copying, use, or modification of this information.
              </p><p>
              <em>Landing last minute</em> never shares any personally identifying information except where required to by law.
              </p><p>
              <em>Landing last minute</em> may link to external services that are not operated by us. <em>Landing last minute</em> cannot accept responsibility or liability for the privacy policy or practices of these sites.
              </p><p>
              Use of this website will be regarded as acceptance of our practices regarding privacy and personal information. If you have any questions about how we handle user data and personal information, please contact us here: <Mailto email="privacy@landinglastminute.com"></Mailto>
              </p><p>
              This policy is effective as of 21 September, 2019.
            </p>
            <p>
              Find out more about Landing Last Minute <Link to={'/about'}>here</Link>.
            </p>
            */

export default AdvertisingPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`